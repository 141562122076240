<template>
    <dashboard-layout>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col>
                        {{ track.title }}
                    </v-col>
                    <v-col class="text-right">
                        <v-btn text :to="{name: 'admin.tracks.index'}">return</v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-row>
                        <v-col>
                            <v-text-field v-model="track.created_at" disabled></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </dashboard-layout>
</template>

<script>
import Track from "@/models/Track";
import DashboardLayout from "@/layouts/DashboardLayout";

export default {
    name: "tracks-show",
    components: {DashboardLayout},
    data: function () {
        return {
            track: {}
        }
    },
    async mounted() {
        const trackId = this.$route.params.id
        this.track = await Track.find(trackId)
    },
}
</script>

<style scoped>

</style>
